<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="dark"
    class="card-data-view items-no-padding"
    :class="{ 'card-data-view-max': cardViewType == 'PROPOSTA' }"
    spacer
    v-model="showCardDataView"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ cardViewTitle }}</h4>
      <feather-icon
        icon="XIcon"
        @click="onCloseClick"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>
    <component
      :is="scrollbarTag"
      class="scroll-area--card-data-view pt-4 pb-6"
      :settings="scrollSettings"
    >
      <broker-data-view-content
        class="p-8"
        @refreshPipe="refreshPipe"
        v-if="cardViewType == 'CORRETORA_PEP'"
        :data="cardContentData"
        :currentPipe="currentPipe"
        :cardId="cardViewId"
        :stepName="stepName"
        :cardMovementUser="cardMovementUser"
        :ownerCard="ownerCard"
        :ownerList="ownerList"
        :cardPriorityList="cardPriorityList"
        :priorityDataClickedCard="priorityDataClickedCard"
      ></broker-data-view-content>
      <nomeation-request-data-view-content
        @refreshPipe="refreshPipe"
        v-if="cardViewType == 'NOMEACAO_TOMADOR'"
        :data="cardContentData"
        :cardId="cardViewId"
        :stepName="stepName"
        :cardMovementUser="cardMovementUser"
        :currentPipe="currentPipe"
        :ownerCard="ownerCard"
        :ownerList="ownerList"
        :cardPriorityList="cardPriorityList"
        :priorityDataClickedCard="priorityDataClickedCard"
      ></nomeation-request-data-view-content>
      <cCGDataViewContent
        @refreshPipe="refreshPipe"
        v-if="cardViewType == 'ANALISE_CCG'"
        :data="cardContentData"
        :cardId="cardViewId"
        :stepName="stepName"
        :cardMovementUser="cardMovementUser"
        :currentPipe="currentPipe"
        :ownerCard="ownerCard"
        :ownerList="ownerList"
        :cardPriorityList="cardPriorityList"
        :priorityDataClickedCard="priorityDataClickedCard"
      ></cCGDataViewContent>
      <ProposalDataViewContent
        @refreshPipe="refreshPipe"
        v-if="
          cardViewType == 'PROPOSTA' ||
            cardViewType == 'GRUPO_ECONOMICO' ||
            cardViewType == 'TOMADOR_QSA_PEP' ||
            cardViewType == 'SEGURADO_QSA_PEP'|| 
            cardViewType =='PROPOSTA_RENOVACAO'
        "
        :cardType="cardViewType"
        :data="cardContentData"
        :socios="data"
        :currentPipe="currentPipe"
        :cardId="cardViewId"
        @reload="$emit('reload')"
        :permissoesCocorretagemMapfre="permissoesCocorretagemMapfre"
        :permissoesResseguroMapfre="permissoesResseguroMapfre"
        :permissoesCosseguroMapfre="permissoesCosseguroMapfre"
        :stepName="stepName"
        :cardMovementUser="cardMovementUser"
        :ownerCard="ownerCard"
        :ownerList="ownerList"
        :cardPriorityList="cardPriorityList"
        :priorityDataClickedCard="priorityDataClickedCard"
        :cardInfos="cardInfos"
      ></ProposalDataViewContent>
      <EndorsementDataViewContent
        @refreshPipe="refreshPipe"
        v-if="
          ['ENDOSSO_CANCELAMENTO', 'ENDOSSO_ALTERACAO', 'ENDOSSO_ANIVERSARIO'].includes(cardViewType)
        "
        :cardType="cardViewType"
        :data="cardContentData"
        :cardId="cardViewId"
        :stepName="stepName"
        :cardMovementUser="cardMovementUser"
        :currentPipe="currentPipe"
        :ownerCard="ownerCard"
        :ownerList="ownerList"
        :cardPriorityList="cardPriorityList"
        :priorityDataClickedCard="priorityDataClickedCard"
      ></EndorsementDataViewContent>
      <!--<EconomicGroupDataViewContent @refreshPipe="refreshPipe" v-if="cardViewType == 'GRUPO_ECONOMICO'" :data="cardContentData"></EconomicGroupDataViewContent>-->
      <DocumentsDataViewContent
        @refreshPipe="refreshPipe"
        v-if="
          cardViewType == 'ANALISE_DOCUMENTACAO_CORRETORA' ||
            cardViewType == 'ANALISE_CONFORMIDADE_CORRETORA'
        "
        :data="cardContentData"
        :cardId="cardViewId"
        :metasValidationClickedCard="metasValidationClickedCard"
        :stepName="stepName"
        :cardMovementUser="cardMovementUser"
        :currentPipe="currentPipe"
        :ownerCard="ownerCard"
        :ownerList="ownerList"
        :cardPriorityList="cardPriorityList"
        :priorityDataClickedCard="priorityDataClickedCard"
      ></DocumentsDataViewContent>
      <BranchActivityDataViewContent
        @refreshPipe="refreshPipe"
        v-if="cardViewType == 'RAMO_ATIVIDADE'"
        :cardType="cardViewType"
        :data="cardContentData"
        :cardId="cardViewId"
        :stepName="stepName"
        :cardMovementUser="cardMovementUser"
        :currentPipe="currentPipe"
        :ownerCard="ownerCard"
        :ownerList="ownerList"
        :cardPriorityList="cardPriorityList"
        :priorityDataClickedCard="priorityDataClickedCard"
      ></BranchActivityDataViewContent>
      <RequestLimitDataViewContent
        @refreshPipe="refreshPipe"
        v-if="cardViewType == 'REQUEST_LIMIT'"
        :data="cardContentData"
        :cardId="cardViewId"
        :stepName="stepName"
        :cardMovementUser="cardMovementUser"
        :currentPipe="currentPipe"
        :ownerCard="ownerCard"
        :ownerList="ownerList"
        :cardPriorityList="cardPriorityList"
        :priorityDataClickedCard="priorityDataClickedCard"
      >
      </RequestLimitDataViewContent>
    </component>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import BrokerDataViewContent from "./DataViewContent/BrokerDataViewContent.vue";
import DocumentsDataViewContent from "./DataViewContent/DocumentsDataViewContent.vue";
import NomeationRequestDataViewContent from "./DataViewContent/NomeationRequestDataViewContent.vue";
import CCGDataViewContent from "./DataViewContent/CCGDataViewContent.vue";
import ProposalDataViewContent from "./DataViewContent/ProposalDataViewContent.vue";
import EndorsementDataViewContent from "./DataViewContent/EndorsementDataViewContent.vue";
import EconomicGroupDataViewContent from "./DataViewContent/EconomicGroupDataViewContent.vue";
import BranchActivityDataViewContent from "./DataViewContent/BranchActivityDataViewContent.vue";
import RequestLimitDataViewContent from "./DataViewContent/RequestLimitsViewContent.vue"
import { mapGetters } from "vuex";

export default {
  components: {
    VuePerfectScrollbar,
    BrokerDataViewContent,
    DocumentsDataViewContent,
    NomeationRequestDataViewContent,
    CCGDataViewContent,
    ProposalDataViewContent,
    EndorsementDataViewContent,
    EconomicGroupDataViewContent,
    BranchActivityDataViewContent,
    RequestLimitDataViewContent
  },
  props: {
    data: {
      type: Object
    },
    currentPipe: {},
    title: {},
    type: {},
    cardId: {},
    metasValidationClickedCard: { type: Array, default: () => [] },
    permissoesCocorretagemMapfre: {
      type: Array,
      default: function() {
        return []
      }
    },
    permissoesResseguroMapfre: {
      type: Array,
      default: function() {
        return []
      }
    },
    permissoesCosseguroMapfre: {
      type: Array,
      default: function() {
        return []
      }
    },
    stepName: {
      type: String
    },
    cardMovementUser: {
      type: Object,
      default: () => {}
    },
    cardPriorityList: {
      type: Array,
      default: () => []
    },
    priorityDataClickedCard: {
      type: Object,
      default: () => {}
    },
    ownerCard: {
      type: String,
      default: null
    },
    ownerList: {
      type: Array,
      default: () => []
    },
    cardInfos: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      scrollSettings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    ...mapGetters("pipe-module", ["showCardDataView"]),
    cardViewType() {
      if (this.type) return this.type;
      else return "";
    },
    cardViewTitle() {
      if (this.title) return this.title;
      else return "Dados do Card";
    },
    cardContentData: {
      get() {
        return this.data || {};
      },
      set(val) {
        this.data = val;
      }
    },
    cardViewId(){
      if (this.cardId) return this.cardId;
      else return "";
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataName &&
        this.dataCategory &&
        this.dataPrice > 0
      );
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    }
  },
  methods: {
    refreshPipe() {
      this.$emit("closeCardDataView");
      this.$emit("refreshPipe");
    },
    onCloseClick() {
      this.$emit("closeCardDataView");
      //this.isCardDataViewActive = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-data-view {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 800px !important;
    max-width: 90vw;
  }
}

.card-data-view-max {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 1000px !important;
    max-width: 90vw;
  }
}

.scroll-area--card-data-view {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  height: auto;
  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
