<template>
  <div class="dados-gerais p-4 pt-2">
    <vs-tabs color="warning">
      <vs-tab label="Dados Corretora">
        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Responsável</p>
              <vs-select v-model="selectedOwnerCard" @change="atualizarResponsavel">
                <vs-select-item
                  v-for="(item, index) in ownerList"
                  :key="index"
                  :value="item"
                  :text="item"
                />
              </vs-select>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Prioridade</p>
              <vs-select v-model="selectedPriorityId" @change="atualizarPrioridade">
                <vs-select-item
                  v-for="(item, index) in cardPriorityList"
                  :key="index"
                  :value="item.Id"
                  :text="item.Name"
                />
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-4" v-if="deveExibirResponsavelMovimentacao">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">{{ stepName }} por</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).Name ? (cardMovementUser || {}).Name : "-" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da movimentação</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).MovingDate ? (cardMovementUser || {}).MovingDate : "-" | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data do cadastro</p>
              <p class="text-lg">{{ corretoraCreateDate|| "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Razão Social</p>
              <p class="text-lg">{{ corretora.Name || "Não informado" }}</p>
            </div>
            <div class="vx-col md:w-6/12 w-full">
              <p class="text-sm font-semibold">CNPJ</p>
              <the-mask
                class=" text-lg invisible-input "
                :mask="['##.###.###/####-##']"
                disabled="true"
                :masked="true"
                :value="corretora.CpfCnpj"
              />
            </div>
            <div class="vx-col w-1/2 mt-4">
              <p class="text-sm font-semibold">Situação</p>
              <p class="text-lg">{{ corretora.SUSEPStatus || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Inscrição Municipal</p>
              <p class="text-lg">{{ corretora.InscricaoMunicipal || "Não informado" }}</p>
            </div>
            <div class="vx-col  w-1/1 mt-4">
              <p class="text-sm font-semibold">Endereço</p>
              <p class="text-lg">{{ corretora.LocationAddress || "Não informado" }}</p>

            </div>
            <div class="vx-col w-1/2 mt-4">
              <p class="text-sm font-semibold">Telefone de contato</p>
              <p class="text-lg">{{ corretora.PhoneNumber || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/1">
              <p class="text-sm font-semibold">Nome Responsável Tecnico - SUSEP</p>
              <p class="text-lg">{{ corretora.NameSusep || "Não informado" }}</p>
            </div>
            <div class="vx-col  w-1/2 mt-4">
              <p class="text-sm font-semibold">Recadastrado</p>
              <p class="text-lg">{{ corretoraRecadastrado || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/1">
              <p class="text-sm font-semibold">Produtos</p>
              <p class="text-lg">{{ corretora.SUSEPProducts || "Não informado" }}</p>
            </div>
          </div>
        </vs-card>
      </vs-tab>

      <vs-tab label="Dados Financeiros">
        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-full">
              <p class="text-sm font-semibold">Banco</p>
              <p class="text-lg">{{ financeiro.BankNumber &&
                  (financeiro.BankNumber + '').padStart(3, '0') +
                    ' - ' +
                    financeiro.BankName || "Não informado" }}</p>
            </div>
            <div class="vx-col w-1/2 mt-4">
              <p class="text-sm font-semibold">Agência</p>
              <p class="text-lg">
                {{ financeiro.BankBranchNumber + ' - ' + financeiro.BankBranchDigit || "Não informado" }}
              </p>
            </div>
            <div class="vx-col w-1/2 mt-4">
              <p class="text-sm font-semibold">Conta</p>
              <p class="text-lg">
                {{ financeiro.BankAccountNumber +
                  ' - ' +
                  financeiro.BankAccountDigit || "Não informado" }}
              </p>
            </div>
          </div>
        </vs-card>

        <div>
          <div class="vx-row mt-12 mb-12">
            <div class="w-2/3 ml-auto">
              <vs-button
                id="btn-respAdministrator"
                @click="filtrarLista('Administrator')"
                class="mr-2"
                v-bind:class="{ 'filtro-ativo': this.filtroAtivo == 1 }"
                type="filled"
                color="rgb(180,180,180)"
                >Responsável Técnico</vs-button
              >
              <vs-button
                id="btn-respFinancialOfficer"
                @click="filtrarLista('FinancialOfficer')"
                class="ml-2"
                v-bind:class="{ 'filtro-ativo': this.filtroAtivo == 2 }"
                type="filled"
                color="rgb(180,180,180)"
                >Responsável Financeiro</vs-button
              >
            </div>
          </div>

          <vs-table
            class="table-list"
            :hoverFlat="true"
            noDataText="Nenhum responsável financeiro."
            :data="listaResponsaveis"
          >
            <template slot="thead">
              <vs-th>CPF</vs-th>
              <vs-th sort-key="ResponsiblePersonName">Nome</vs-th>
              <vs-th>Telefone</vs-th>
              <vs-th sort-key="ResponsiblePersonEmail">E-mail</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                style="border-top: solid 1px black"
              >
                <vs-td
                  :data="data[indextr].ResponsiblePersonDocument"
                  class="text-center"
                >
                  <span>{{ data[indextr].ResponsiblePersonDocument || '-' }}</span>
                </vs-td>

                <vs-td
                  :data="data[indextr].ResponsiblePersonName"
                  class="text-center"
                >
                  <span>{{ data[indextr].ResponsiblePersonName }}</span>
                </vs-td>

                <vs-td
                  :data="data[indextr].ResponsiblePersonPhoneNumber"
                  class="text-center"
                >
                  <span>{{ data[indextr].ResponsiblePersonPhoneNumber }}</span>
                </vs-td>

                <vs-td
                  :data="data[indextr].ResponsiblePersonEmail"
                  class="text-center"
                >
                  <span>{{ data[indextr].ResponsiblePersonEmail }}</span>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-tab>

      <vs-tab label="Documentos" v-if="enableInsertNewDocument">
        <div>
          <div>
            <vs-card
              class="mt-1 p-2"
              v-for="(documento, index) in listaDocumentos"
              :key="index"
            >
              <div class="vx-row flex">
                <div class="vx-col flex-1">
                  <p class="text-sm font-semibold"></p>
                  <p class="text-lg">{{ documento.Name || "Não informado" }}</p>
                  <p class="text-sm pt-4">
                    <b>Status </b>{{ documento.StatusDescription }}
                  </p>
                  <p class="text-sm pt-4">
                    <b>Total de anexos </b>{{ documento.TotalArchives }}
                  </p>
                </div>
                <div class="vx-col">
                  <div class="text-right w-full flex items-center">
                    <button
                      class="onpoint-download-simple download-button"
                      v-if="documento.HasArchives"
                      @click="downloadDocumento(documento)"                    
                    ></button>
                    <button
                      class="onpoint-thumbs-up aprove-button"
                      v-if="
                        documento.RequestDocumentStatusId == 3 && hasPermission
                      "
                      @click="alterarStatusDocumento(documento, 1)"                   
                    ></button>
                   
                    <button
                      class="onpoint-thumbs-down reprove-button"
                      v-if="
                        documento.RequestDocumentStatusId == 3 && hasPermission
                      "
                      @click="alterarStatusDocumento(documento, 4)"                 
                    ></button>                    

                    <button
                      class="onpoint-trash trash-button"
                      v-if="hasPermission"
                      @click="deletarDocumento(documento)"                                    
                    ></button>
                  </div>
                </div>
                <!---<div class="vx-col w-1/4">
                          <p class="text-sm font-semibold">Data de Solicitação</p>
                          <p class="text-lg">{{ documento.CreateDate  | moment('DD/MM/YYYY') }}</p>
                        </div>
                        <div class="vx-col w-1/4">
                          <p class="text-sm font-semibold">Status</p>
                          <p class="text-lg">{{ documento.StatusDescription }}</p>
                        </div>--->
              </div>
            </vs-card>
          </div>
        </div>

        <div class="vx-row" v-if="hasPermission">
          <div class="vx-col text-right w-full mt-2">
            <vs-button
              @click.native="mostrarListaDeDocumentos()"
              color="primary"
            >
              <span class="font-semibold pr-2 pl-2">
                Adicionar documentos
              </span>
            </vs-button>
            <vs-button
              class="ml-5"
              :disabled="!possuiDocumentacaoPendente"
              @click.native="
                enviarParaCorretor('Solicitação de documentos para o corretor.')
              "
              color="primary"
            >
              <span class="font-semibold pr-2 pl-2">
                Enviar para o corretor
              </span>
            </vs-button>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>

    <vs-popup
      class="popup-lista-documentos"
      title="Solicitar documentação"
      :active.sync="popupListaDocumentos"
    >
      <vs-tabs color="warning">
        <vs-tab label="Outros documentos">
          <div class="vx-row">
            <div class="vx-col w-full">
              <div
                class="vx-row"
                v-if="(documentosManuaisParaSolicitar || []).length == 0"
              >
                <div class="vx-col w-full mt-2">
                  <vs-alert color="warning" class="h-auto">
                    Nenhum documento adicionado. Clique no botão de adicionar
                    documentos;
                  </vs-alert>
                </div>
              </div>
              <div
                class="vx-row"
                v-for="(documentoManual,
                index) in documentosManuaisParaSolicitar || []"
                :key="index"
              >
                <div class="vx-col md:w-10/12 w-full mt-5 md:pr-2">
                  <vs-input
                    label-placeholder="Documento"
                    size="large"
                    v-model="documentoManual.Name"
                    class="w-full"
                  />
                </div>
                <div
                  class="vx-col md:w-2/12 w-full mt-5 md:pr-0 md:pl-2 p-0 flex items-center"
                >
                  <button
                    class="onpoint-trash remove-document-button"
                    @click="removeDocumentoManual(index)"                                      
                  ></button>                  
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-button
                    icon-after
                    @click="adicionaDocumentoManual()"
                    class="font-semibold mt-2 float-right"
                    color="primary"
                    type="flat"
                    icon="add_circle"
                  >
                    Adicionar novo Documento
                  </vs-button>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col pt-5 text-right w-full mt-2">
                  <vs-button
                    @click.native="solicitarDocumentosManuais()"
                    color="primary"
                  >
                    <span class="font-semibold pr-2 pl-2">
                      Solicitar documentação
                    </span>
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </vs-popup>

    <vs-popup
      class="popup-download-arquivos"
      title="Anexos do documento"
      :active.sync="popupListaArquivos"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <h5>{{ (documentoSelecionado || {}).Name }}</h5>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col pt-5 w-full mt-2">
          <vs-card
            class="mt-1 p-2"
            v-for="(archive, index) in listaDocumentosDetalhes"
            :key="index"
          >
            <div class="vx-row flex">
              <div class="vx-col flex-1">
                <p class="text-sm font-semibold"></p>
                <p class="text-lg">{{ archive.Name || "Não informado" }}</p>
                <p class="text-sm">
                  <b>Anexado em </b
                  >{{ archive.CreateDate | moment("DD/MM/YYYY") }}
                </p>
              </div>
              <div class="vx-col">
                <div class="text-right w-full flex items-center">
                  <vs-button
                    class="delete"
                    @click="baixarArquivo(archive)"
                    color="primary"
                    type="flat"
                    size="large"
                    icon="get_app"
                  ></vs-button>
                </div>
              </div>
            </div>
          </vs-card>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { kanbanProvider } from "@/providers/kanban.provider";
import { mask, TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import * as Enums from "@/utils/enums";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import environment from "../../../environment";

export default {
  name: "documentsDataViewContent",
  directives: { mask },
  components: { TheMask },
  data() {
    return {
      listaDocumentos: [],
      uploadDocName: "",
      listaDocumentosDetalhes: [],
      modalListarDocumentos: false,
      popupListaArquivos: false,
      documentoSelecionado: undefined,
      popupListaDocumentos: false,
      documentosManuaisParaSolicitar: [],
      financeiro: {},
      responsibleTypeSelected: 0,
      filtroAtivo: 0,
      perfilHelpers: PerfilHelpers,
      Newe: false,
      priorityCard: null,
      selectedPriorityId: null,
      selectedOwnerCard: null,
    };
  },
  props: {
    data: {
      type: Object
    },
    cardId: {
      type: String
    },
    metasValidationClickedCard: { type: Array, default: () => [] },
    stepName: {
      type: String
    },
    cardMovementUser: {
      type: Object,
      default: () => {}
    },
    cardPriorityList: {
      type: Array,
      default: () => []
    },
    priorityDataClickedCard: {
      type: Object,
      default: () => {}
    },
    ownerCard: {
      type: String,
      default: null
    },
    ownerList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("pipe-module", { currentPipe: "pipe" }),

    hasPermission() {
      return this.perfilHelpers.checkPermissionKanban(this.currentPipe.id);
    },

    listaResponsaveis() {
      if (this.financeiro.BrokerResponsibles) {
        return this.responsibleTypeSelected
          ? this.financeiro.BrokerResponsibles.filter(
              r => r.ResponsiblePersonTypeId === this.responsibleTypeSelected
            )
          : this.financeiro.BrokerResponsibles;
      } else {
        return [];
      }
    },
    corretoraRecadastrado: {
      get() {
        if (
          !this.corretora.SUSEPReregister ||
          this.corretora.SUSEPReregister == "False"
        )
          return "Não";
        else return "Sim";
      }
    },
    corretoraCreateDate: {
      get() {
        return this.$moment(this.corretora.CreateDate).format("DD/MM/YYYY");
      }
    },
    corretora: {
      get() {
        return this.data || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },
    carId: {
      get() {
        return this.cardId || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },
    possuiDocumentacaoPendente: {
      get() {
        const pendentes = this.listaDocumentos.filter(item => {
          return item.Status == 2 || item.Status == 4;
        });
        return pendentes.length > 0;
      }
    },
    enableInsertNewDocument() {
      if (this.metasValidationClickedCard) {
        let isEnabled = this.metasValidationClickedCard.find(
          (meta) => meta.name === "EnableInsertNewDocument"
        );
        return isEnabled ? isEnabled.value : true;
      } else {
        return true;
      }
    },
    deveExibirResponsavelMovimentacao() {
      return ['Aprovado', 'Reprovado', 'Recusado', 'Cancelado'].includes(this.stepName)
    }
  },
  watch: {
    selectedPriorityId(newId) {
      this.priorityCard = this.cardPriorityList.find(option => option.Id === newId);
    },
    priorityCard(newPriority) {
      if (newPriority) {
        this.selectedPriorityId = newPriority.Id;
      }
    },
    ownerCard(newVal) {
      this.selectedOwnerCard = newVal;
    }
  },
  async mounted() {
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    await this.carregarDocumentos(this.corretora);
    await this.carregarDadosFinanceiros(this.corretora.UniqueId);
    let Newe = parseInt(environment.CompanyId.Newe);
    this.Newe = portalConfig.CompanyId == Newe ? true : false;

    if(this.ownerCard){
      this.selectedOwnerCard = this.ownerCard;
    }

    if (this.priorityDataClickedCard && this.priorityDataClickedCard.Id) {
      this.selectedPriorityId = this.priorityDataClickedCard.Id;
    }
  },
  methods: {
    ...mapActions("documentos-corretora", [
      "obterDocumentosPorCorretora",
      "getDocumentoPorId",
      "getUrlFileDownload"
    ]),
    ...mapActions("corretor-module", ["getDadosFinanceiros"]),

    async carregarDocumentos(corretora) {
      await this.$onpoint.loading(async() => {
        return await this.obterDocumentosPorCorretora(corretora.Id).then(response => {
          this.listaDocumentos = response;
        });
      });
    },

    async atualizarResponsavel() {
      if (this.selectedOwnerCard != "") {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.kanbanUpdateCardOwner({
            cardId: this.cardId,
            owner: this.selectedOwnerCard
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    },

    adicionaDocumentoManual() {
      if (!this.documentosManuaisParaSolicitar)
        this.documentosManuaisParaSolicitar = [];

      this.documentosManuaisParaSolicitar.push({ Name: "" });
    },
    removeDocumentoManual(index) {
      this.documentosManuaisParaSolicitar.splice(index, 1);
    },

    mostrarListaDeDocumentos() {
      this.popupListaDocumentos = true;
    },
    carregarDadosFinanceiros(value) {
      this.getDadosFinanceiros(value).then(res => {
        this.financeiro = res;
      });
    },
    filtrarLista(type) {
      type = Enums.ResponsiblePersonType[type];

      if (this.responsibleTypeSelected == type) {
        this.responsibleTypeSelected = this.filtroAtivo = 0;
      } else {
        this.responsibleTypeSelected = this.filtroAtivo = type;
      }
    },

    async downloadDocumento(documento) {
      this.documentoSelecionado = documento;
      this.popupListaArquivos = false;

      await this.$onpoint.loading(async() => {
        return await this.getDocumentoPorId(documento.Id)
          .then(response => {
            if (response.length === 0) {
              this.listaDocumentosDetalhes = [
                {
                  OriginalFileName:
                    "Nenhum documento cadastrado nessa categoria"
                }
              ];
              this.popupListaArquivos = true;
            } else {
              this.listaDocumentosDetalhes = response;

              if (this.listaDocumentosDetalhes.length == 1) {
                this.baixarArquivo(this.listaDocumentosDetalhes[0]);
              } else {
                this.popupListaArquivos = true;
              }
            }
            return this.listaDocumentosDetalhes;
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    baixarArquivo(arquivo) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .getArchiveLinkToDownload(arquivo.UniqueId)
          .then(data => {
            window.open(data, "_blank");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    alterarStatusDocumento(documento, status) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .KanbanBrokerChangeStatusRequestDocument({
            RequestDocumentUniqueId: documento.UniqueId,
            StatusId: status,
            BrokerId: this.corretora.Id
          })
          .then(data => {
            this.listaDocumentos = data.BrokerRequestDocuments;
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    deletarDocumento(documento) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .KanbanBrokerDeleteRequestDocument({
            BrokerRequestDocumentUniqueId: documento.UniqueId,
            BrokerId: this.corretora.Id
          })
          .then(data => {
            this.listaDocumentos = data.BrokerRequestDocuments;
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    solicitarDocumentosManuais() {
      const lista = [];
      for (
        let x = 0;
        x < (this.documentosManuaisParaSolicitar || []).length;
        x++
      ) {
        if (
          this.documentosManuaisParaSolicitar[x].Name &&
          this.documentosManuaisParaSolicitar[x].Name.length > 0
        )
          lista.push(this.documentosManuaisParaSolicitar[x].Name);
      }

      if ((lista || []).length == 0) {
        this.$onpoint.errorModal("Adicione ao menos um documento.");
        return;
      }

      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanBrokerRequestDocument({
            RequestDocuments: lista,
            BrokerId: this.corretora.Id
          })
          .then(data => {
            this.documentosManuaisParaSolicitar = [];

            this.listaDocumentos = data.BrokerRequestDocuments;
            this.popupListaDocumentos = false;
            this.$onpoint.successModal("Documentos adicionados com sucesso.");
            //location.reload();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    enviarParaCorretor(obs) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .KanbanBrokerRequestDocumentsSendToBroker({
            Observation: obs || this.observation,
            BrokerId: this.corretora.Id
          })
          .then(data => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
            this.$emit("refreshPipe");
            //location.reload()
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async atualizarPrioridade() {
      let data = {
        PipeId: this.priorityDataClickedCard.PipeId,
        CardId: this.priorityDataClickedCard.CardId,
        PipeCardPriority : this.priorityCard.Id
      }

      await this.$onpoint.loading(async () => {
        return await kanbanProvider.updatePriorityKanbanCard(data)
          .then(response => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(error => {
            this.$onpoint.errorModal(error);
          });
      })
    },
  }
};
</script>

<style lang="scss">
button.filtro-ativo {
  background: #0f6eb4 !important;

  &:hover {
    box-shadow: #0f6eb4 0px 8px 25px -8px !important;
  }
}

.table-list {
  margin-top: 20px;

  .vs-table-text {
    justify-content: center;
  }

  .vs-con-tbody {
    border: 0;
  }

  td {
    border-top: solid 1px #e2e2e2;

    padding: 25px !important;
  }

  .tr-values:not(.is-expand):hover {
    cursor: default;
  }

  .invisible-input{
    background-color: transparent;
    border: none;
    font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  }  
}

.trash-button{    
  border:none;
  background-color:transparent; 
  font-size:32px;       
  padding:5px;
    &::before{
      color:red;   
    }

  &:hover{
    background-color:#f0f0f0;
  }
}

.download-button{    
  border:none;
  background-color:transparent; 
  font-size:32px;       
  padding:5px;
    &::before{
      color:blue;   
    }

  &:hover{
    background-color:#f0f0f0;
  }
}

.aprove-button{    
  border:none;
  background-color:transparent; 
  font-size:32px;       
  padding:5px;
    &::before{
      color:green;   
    }

  &:hover{
    background-color:#f0f0f0;
  }
}
.reprove-button{    
  border:none;
  background-color:transparent; 
  font-size:32px;       
  padding:5px;
    &::before{
      color:#ff9f43;   
    }

  &:hover{
    background-color:#f0f0f0;
  }
}
.remove-document-button{
  border:none;
  background-color:transparent; 
  font-size:32px;       
  padding:5px;
  position:relative;
  top:10px;
  &::before{
    color:#0e65a5;   
  }

  &:hover{
    background-color:#f0f0f0;
  }
}
</style>
