var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4 pl-4 pr-4" },
    [
      _c(
        "vs-tabs",
        { attrs: { color: "warning" } },
        [
          _c(
            "vs-tab",
            { attrs: { label: "Resumo da Corretora" } },
            [
              _c("vs-card", { staticClass: "mt-4 p-8 dados-gerais" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Responsável"),
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarResponsavel },
                          model: {
                            value: _vm.selectedOwnerCard,
                            callback: function ($$v) {
                              _vm.selectedOwnerCard = $$v
                            },
                            expression: "selectedOwnerCard",
                          },
                        },
                        _vm._l(_vm.ownerList, function (item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item, text: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold mb-1" }, [
                        _vm._v("Prioridade"),
                      ]),
                      _c(
                        "vs-select",
                        {
                          on: { change: _vm.atualizarPrioridade },
                          model: {
                            value: _vm.selectedPriorityId,
                            callback: function ($$v) {
                              _vm.selectedPriorityId = $$v
                            },
                            expression: "selectedPriorityId",
                          },
                        },
                        _vm._l(_vm.cardPriorityList, function (item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item.Id, text: item.Name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.deveExibirResponsavelMovimentacao
                  ? _c("div", { staticClass: "vx-row mt-4" }, [
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v(_vm._s(_vm.stepName) + " por"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.cardMovementUser || {}).Name
                                  ? (_vm.cardMovementUser || {}).Name
                                  : "-"
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-col w-1/2" }, [
                        _c("p", { staticClass: "text-sm font-semibold" }, [
                          _vm._v("Data da movimentação"),
                        ]),
                        _c("p", { staticClass: "text-lg" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("moment")(
                                  (_vm.cardMovementUser || {}).MovingDate
                                    ? (_vm.cardMovementUser || {}).MovingDate
                                    : "-",
                                  "DD/MM/YYYY HH:mm"
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-gerais" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Data do cadastro"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretoraCreateDate || "Não informado")
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-gerais" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Razão Social"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(_vm._s(_vm.corretora.Name || "Não informado")),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("CNPJ Corretora"),
                      ]),
                      _c("the-mask", {
                        staticClass: "text-lg invisible-input",
                        attrs: {
                          mask: ["##.###.###/####-##"],
                          disabled: "true",
                          masked: true,
                          value: _vm.corretora.CpfCnpj,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2 mt-2 mb-6" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Situação"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretora.SUSEPStatus || "Não informado")
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-gerais" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/1" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Endereço"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretora.LocationAddress || "Não informado")
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/1 mt-4" },
                    [
                      _c("p", { staticClass: "text-sm font-semibold" }, [
                        _vm._v("Telefone de contato"),
                      ]),
                      _vm.corretora.PhoneNumber
                        ? _c("the-mask", {
                            staticClass: "text-lg invisible-input",
                            attrs: {
                              mask: ["(##) ####-####", "(##) #####-####"],
                              disabled: "true",
                              masked: true,
                              value:
                                _vm.corretora.PhoneNumber || "Não informado",
                            },
                          })
                        : _c("p", { staticClass: "text-lg" }, [
                            _vm._v(" Não informado "),
                          ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-gerais" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Nome Responsável Tecnico - SUSEP"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretora.NameSusep || "Não informado")
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col w-1/2" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Recadastrado"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretoraRecadastrado || "Não informado")
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("vs-card", { staticClass: "mt-4 p-8 dados-gerais" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-1/1" }, [
                    _c("p", { staticClass: "text-sm font-semibold" }, [
                      _vm._v("Produtos"),
                    ]),
                    _c("p", { staticClass: "text-lg" }, [
                      _vm._v(
                        _vm._s(_vm.corretora.SUSEPProducts || "Não informado")
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "dados-gerais p-4 pt-2" }, [
                _c("div", { staticClass: "vx-row mt-5" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-12/12 w-full md:mt-0 mt-5" },
                    [
                      _vm.currentPipe.name === "PEP"
                        ? _c("TableSocios", {
                            staticClass: "kaban",
                            attrs: {
                              Socios: _vm.data.UniqueId,
                              TypeText: "CORRETORA_PEP",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("vs-tab", { attrs: { label: "Histórico do Motor" } }, [
            !(_vm.corretora || {}).BrokerExecutionLogs ||
            (_vm.corretora || {}).BrokerExecutionLogs.length == 0
              ? _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full mt-2" },
                    [
                      _c(
                        "vs-alert",
                        { staticClass: "h-auto", attrs: { color: "warning" } },
                        [
                          _vm._v(
                            "\n            Nenhuma regra foi executada nessa proposta.\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col pt-5 w-full mt-2" },
                _vm._l(
                  (_vm.corretora || {}).BrokerExecutionLogs,
                  function (regra, index) {
                    return _c(
                      "vs-collapse",
                      { key: index, attrs: { type: "margin" } },
                      [
                        _c("vs-collapse-item", [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("p", { staticClass: "text-sm font-semibold" }),
                              _c("p", { staticClass: "text-lg" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      (regra || {}).EngineExecutionType.Name ||
                                        "Não informado"
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _c("p", { staticClass: "text-sm" }, [
                                _c("b", [_vm._v("Executada em ")]),
                                _vm._v(
                                  _vm._s(
                                    _vm._f("moment")(
                                      (regra || {}).CreateDate,
                                      "DD/MM/YYYY"
                                    )
                                  ) + "\n                "
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "vx-row flex" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col flex-1" },
                              [
                                _c(
                                  "vs-table",
                                  {
                                    attrs: {
                                      data:
                                        ((regra || {}).RuleEngineResult || {})
                                          .RuleResults || [],
                                      noDataText:
                                        "Nenhum histórico de regras executado.",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ data }) {
                                            return _vm._l(
                                              data,
                                              function (tr, indextr) {
                                                return _c(
                                                  "vs-tr",
                                                  {
                                                    key: indextr,
                                                    style: _vm.getRowStyle(
                                                      data[indextr]
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data: indextr,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "flex",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  indextr + 1
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data: data[indextr]
                                                            .RuleName,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "flex",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  data[indextr]
                                                                    .RuleName
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data: data[indextr]
                                                            .Success,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "flex",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  data[indextr]
                                                                    .Success
                                                                    ? "Sim"
                                                                    : "Não"
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-td",
                                                      {
                                                        attrs: {
                                                          data: data[indextr]
                                                            .Messages,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "flex",
                                                          },
                                                          [
                                                            (
                                                              data[indextr]
                                                                .Messages || []
                                                            ).length == 0
                                                              ? _c("span")
                                                              : _vm._e(),
                                                            (
                                                              data[indextr]
                                                                .Messages || []
                                                            ).length > 0
                                                              ? _c("div", [
                                                                  _c(
                                                                    "ul",
                                                                    _vm._l(
                                                                      data[
                                                                        indextr
                                                                      ]
                                                                        .Messages ||
                                                                        [],
                                                                      function (
                                                                        message,
                                                                        indexMsg
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key: indexMsg,
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  " +
                                                                                _vm._s(
                                                                                  message
                                                                                ) +
                                                                                "\n                                "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "thead" },
                                      [
                                        _c("vs-th", [_vm._v(" Etapa ")]),
                                        _c("vs-th", [_vm._v(" Regra ")]),
                                        _c("vs-th", [
                                          _vm._v(" Executada com sucesso? "),
                                        ]),
                                        _c("vs-th", [_vm._v(" Mensagem ")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }